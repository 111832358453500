const shopBasePath = "/";

export const shopPaths = {
  basePath: {
    "name": "shop",
    path: shopBasePath,
  },
  auth: {
    my: "my",
    myOrders: {
      name: "My Orders",
      path: "orders",
      get fullPath() {
        return `${shopPaths.auth.my}/${this.path}`;
      }
    },
    myAccountSettings: {
      name: "My account settings",
      path: "account-settings",
      get fullPath() {
        return `${shopPaths.auth.my}/${this.path}`;
      }
    },
    myAddresses: {
      name: "My Addresses",
      path: "addresses",
      get fullPath() {
        return `${shopPaths.auth.my}/${this.path}`;
      }
    },
    myPaymentMethods: {
      name: "My Addresses",
      path: "payments-methods",
      get fullPath() {
        return `${shopPaths.auth.my}/${this.path}`;
      }
    },
    shoppingBag: {
      name: "Shopping Bag",
      path: "shopping-bag",
      unAuthPath: "/shopping-bag",
      get fullPath() {
        return `/${shopPaths.auth.my}/${this.path}`;
      }
    },
    placeOrder: {
      name: "Place Order",
      path: "place-order",
      unAuthPath: "/place-order",
      get fullPath() {
        return `/${shopPaths.auth.my}/${this.path}`;
      }
    },
    trackOrder: {
      name: "Track Order",
      path: "track-order",
      unAuthPath: "/track-order",
      get fullPath() {
        return `/${shopPaths.auth.my}/${this.path}`;
      }
    }
  },
  unAuth: {
    home: {
      name: "home",
      path: shopBasePath,
    },
    product: {
      name: "Product",
      path: "product"
    },
    productList: {
      name: "Products",
      path: "/products"
    },
    completedProjects: {
      name: "Completed projects",
      path: `completed-projects`,
    },
    contactUs: {
      name: "Contact Us",
      path: `contact-us`,
    },
    terms: {
      path: "/terms-conditions",
      name: "Terms & Conditions",
    },
    privacy: {
      path: "/privacy-policy",
      name: "Privacy Policy",
    },
    signUp: {
      name: "Sign Up",
      path: "/sign-up",
    },
    emailConfirm: {
      name: "Email Confirm",
      path: "/email-confirm",
    },
    login: {
      name: "Log In",
      path: `/log-in`,
    },
    forgot: {
      name: "Forgot Password",
      path: "/forgot-password",
    },
    resend: {
      name: "Resend Email",
      path: "/resend-email",
    },
    reset: {
      name: "Reset Password",
      path: "reset-password",
    }
  },
}

import './App.css';
import { ShopRouter }           from "routing";
import { ShopThemeProvider }    from "components/theme";
import { CookiesProvider }      from 'react-cookie';
import { SnackbarProvider }     from 'react-notistack';
import StoreConsumer from "global/StoreConsumer";
import { useShopInitialize } from "store/hooks";
import ShopIndexedDb from "./indexedDB/ShopIndexedDb";

ShopIndexedDb.initialize();

function App() {
  useShopInitialize();

  return (
    <CookiesProvider>
      <ShopThemeProvider>
        <SnackbarProvider maxSnack={3} anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}>
          <ShopRouter />
          <StoreConsumer />
        </SnackbarProvider>
      </ShopThemeProvider>
    </CookiesProvider>
  );
}

export default App;

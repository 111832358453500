import { AuthPagesWrapper } from "pages/auth/components";
import { Box } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import ReplyIcon from '@mui/icons-material/Reply';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { EmailField } from "components/main/form";
import { useFetch } from "pages/auth/hooks";
import { customerEmailResendRequest } from "services/api/auth/authCustomerService";
import { MESSAGES } from "pages/auth/messages";
import { useLocation } from "react-router-dom";

const Resend = ({ props }) => {
  const { state } = useLocation();
  const defaultEmail = state?.data?.emailAddress;
  const {
    errors,
    register,
    isSubmitting,
    isSubmitSuccessful,
    handleSubmit,
    handleFetchSubmit,
    getValues,
  } = useFetch(customerEmailResendRequest, MESSAGES.resendEmail);
  const sentEmail = getValues("emailAddress");

  const headerOptions = isSubmitSuccessful
    ? {
      header: "Check your email",
      description: `We sent a confirmation link to ${sentEmail}`,
      HeaderIcon: EmailOutlinedIcon
    }
    : {
      header: "Resend confirmation email?",
      description: "No worries, we’ll send you email verification",
      HeaderIcon: ReplyIcon
    }

  return (
    <AuthPagesWrapper
      {...headerOptions}
      showBackLink={true}
      headerAlign="center"
    >
      <Box
        component="form"
        onSubmit={handleSubmit(handleFetchSubmit)}
        noValidate
      >
        {isSubmitSuccessful
          ?
          <LoadingButton
            loading={isSubmitting}
            type={"submit"}
            fullWidth
            size={"large"}
            variant="contained"
            sx={{ mt: 2 }}
          >
            Didn't receive? Click to resend
          </LoadingButton>
          :
          <>
            <EmailField
              register={register}
              errors={errors}
              defaultValue={defaultEmail}
            />
            <LoadingButton
              loading={false}
              type={"submit"}
              fullWidth
              size={"large"}
              variant="contained"
              sx={{ mt: 4 }}
            >
              Resend email verification
            </LoadingButton>
          </>
        }
      </Box>
    </AuthPagesWrapper>
  );
};

export default Resend;

import AppBar            from "@mui/material/AppBar";
import Box               from "@mui/material/Box";
import Toolbar           from "@mui/material/Toolbar";
import SearchBar         from "components/main/ShopBar/SearchBar";
import TopBar            from "components/main/ShopBar/TopBar";
import ShopMenu          from "components/main/ShopBar/ShopMenu";
import { Link }          from "react-router-dom";
import StatusBar         from "components/main/ShopBar/StatusBar";
import ShopMobileBar     from "components/main/ShopBar/ShopMobileBar";
import { Typography }    from "@mui/material";
import { useSelector }   from "react-redux";
import customerSelectors from "../../../store/customer/customerSelectors";
import AlertBar from "./AlertBar";

export default function ShopBar() {
  const { fullName } = useSelector(customerSelectors.getInfo);
  const isLoggedIn = useSelector(customerSelectors.getIsLoggedIn);
  return (
    <Box sx={{ flexGrow: 1, marginBottom: { xs: "64px", md: 0 } }}>
      <AppBar sx={{ position: { xs: "fixed", md: "static", top: 0, left: 0, right: 0, zIndex: 10 } }} color="appBar">
        <TopBar/>
        {/*<AlertBar />*/}
        <Toolbar>
          <Box sx={{
            flexDirection: { xs: "row", md: "column" }, width: "100%", maxWidth: "1152px", mx: "auto", px: { xs: 0, md: 7.5 },
            display: "flex", justifyContent: "space-between"
          }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                pt: { xs: 0, md: 2 },
                flexGrow: 1
              }}
            >
              <Box sx={{ height: { xs: "30px", md: "44px" } }}>
                <Link to="/">
                  <img src="/logo512.png" alt="logo" style={{ height: "100%" }}/>
                </Link>
              </Box>
              <Box sx={{ display: { xs: "none", md: "flex" }, width: "100%", position: "relative" }}>
                <SearchBar/>
              </Box>
              <Box sx={{ flexGrow: 1 }}/>
              {isLoggedIn &&
              <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", mr: { md: 2 } }}>
                <Typography variant={"body3"}>Hello</Typography>
                <Typography variant={"body3"}>{fullName.split(" ")[ 0 ]}</Typography>
              </Box>
              }
              <StatusBar/>
            </Box>
            <ShopMenu/>
            <ShopMobileBar/>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
